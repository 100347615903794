import React, { useState } from 'react';

export const myContext = React.createContext();

const Provider = props => {
    const [sideNav, setSideNav] = useState(false)
    const [mainNav, setMainNav] = useState(false)
  return (
    <myContext.Provider
    value={{
      sideNav,
      toggleSideNav: () => sideNav ? setSideNav(false) : setSideNav(true),
      sideNavOn: () =>  setSideNav(true),
      sideNavOff: () =>  setSideNav(false),
      mainNav,
      toggleMainNav: () => mainNav ? setMainNav(false) : setMainNav(true),
      mainNavOff: () => setMainNav(false)
    }}>
      {props.children}
    </myContext.Provider>
  )
};

export default ({ element }) => (
  <Provider>
    {element}
  </Provider>
);
