// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-all-js": () => import("./../../../src/pages/all.js" /* webpackChunkName: "component---src-pages-all-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-available-list-js": () => import("./../../../src/templates/availableList.js" /* webpackChunkName: "component---src-templates-available-list-js" */),
  "component---src-templates-available-painting-js": () => import("./../../../src/templates/availablePainting.js" /* webpackChunkName: "component---src-templates-available-painting-js" */),
  "component---src-templates-media-js": () => import("./../../../src/templates/media.js" /* webpackChunkName: "component---src-templates-media-js" */),
  "component---src-templates-painting-js": () => import("./../../../src/templates/painting.js" /* webpackChunkName: "component---src-templates-painting-js" */),
  "component---src-templates-statements-js": () => import("./../../../src/templates/statements.js" /* webpackChunkName: "component---src-templates-statements-js" */),
  "component---src-templates-year-js": () => import("./../../../src/templates/year.js" /* webpackChunkName: "component---src-templates-year-js" */)
}

